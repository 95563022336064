.container.contact_container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 50%;
  gap: 12%;
}

.contact_options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact_option {
  background: var(--color-bg-var);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact_option:hover {
  background: transparent;
  border-color: var(--color-primary-var);
}

.contact_option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact_option a {
  margin-bottom: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5;
  background: transparent;
  border: 2px solid var(--color-primary-var);
  resize: none;
  color: var(--color-white);
}

/*====================Media Querie (Meduim Devices 'Tablets')===================*/

@media screen and (max-width: 1024px) {
  .container.container.contact_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/*====================Media Querie (Small Devices 'Tablets')===================*/

@media screen and (max-width: 600px) {
  .container.container.contact_container {
    grid-template-columns: 1fr;
    width: var(--container-width-sm);
  }
}
